import React, { useState } from "react";
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './screen/LoginPage';
import OTPPage from './screen/OTPPage';
import Dashboard from './screen/Dashboard';
import './App.css';
global.noddydata = {};
const App = () => {
  return (
    <div className="App">
    <Router>
         <Routes>
            <Route path='/' Component={LoginPage} />
            <Route path='/otp' Component={OTPPage} />
            <Route path='/dashboard' Component={Dashboard} />
         </Routes>
     </Router>
     </div>
  )
};

export default App;
