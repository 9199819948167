import React, { useState,useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import {Table,Form,Image,Button,Container,Navbar } from 'react-bootstrap';
import {TextField,CircularProgress } from '@mui/material';
const Dashboard = (props) => {
    const navigate = useNavigate();
    const logo = require('../images/upload.png');
    const [file, setFile] = useState(null)
    const [data, setData] = useState([])
    const [loading, setloading] = useState(false);
    const ImgBG = require('../images/completeLogo.png');
    const logout = () => {
        localStorage.clear('token');
        navigate('/');
    }
    const getOrders = async () => {
        setloading(true);
        var token = localStorage.getItem('token');
        console.log("token value",token);
        try {
          const res = await axios.get(`http://139.59.77.157/api/v1/business-ower-order/`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
          });
          if (res?.data) {
            if(res?.data.detail == 'Invalid token.'){
                setloading(false);
                logout();
            }else{
                setData(res.data);
                setloading(false);
            }
          }else{
            console.log("something went wrong ! Error")
          }
        } catch (error) {
            console.log(error);
            setloading(false);
        }
      };

    useEffect(() => {
        getOrders();
        var token = localStorage.getItem('token');
        console.log("token dashboaord",token);
    }, [])
    
    const onUploadFun = async (event) => {
        var token = localStorage.getItem('token');
        setloading(true);
        event.preventDefault();
        if(file){
            const formData = new FormData();
            formData.append("csv", file, file.filename);
            try {
                const res = await axios.post(`http://139.59.77.157/api/v1/bulk-upload`, formData, {
                    headers: {
                        'Content-Type': "multipart/form-data",
                        "Authorization": `Bearer ${token}`
                    },
                });
                if (res?.data) {
                    getOrders();
                    alert(res?.data.detail[0]);
                    setloading(false);
                }else{
                    getOrders();
                    setloading(false);
                }
            } catch (error) {
                console.log(error);   
            } 
        }
    }
    const getStatus = (status) => {
        let data = {
            open: "Open",
            cancelled: "Cancelled",
            pickup: "Picked Up",
            head_office: "Head Office",
            out_for_delivery: "Out For Delivery",
            intransit: "In-Transit",
            completed: "Completed",
            return_requested: "Requested Returned",
            return_intransit: "Returned In-Transit",
            returned: "Returned",
        };
        return data[status];
    };
    return <div>
         <nav class="bg-light">
            <div className="row">
                <div className="col-md-3">
                    <img src={ImgBG} width="250" alt="" />
                </div>
                <div className="col-md-6">
                    <center>
                        <div class="my-2 my-lg-0" className={"headerText"}>
                            Dashboard
                        </div>
                    </center>
                </div>
            </div>
        </nav>
        <div className="container">
            <div className="DashContainer">
                <div className={"headerTextt"} class="mt-5">
                    Upload Bulk Orders
                </div>
                <br/>
                <div>
                    <form onSubmit={onUploadFun} class="d-flex flex-row">
                        <input
                            type="file"
                            onChange={event => {console.log(event); setFile(event.currentTarget.files[0])}}
                            placeholder="Upload Orders"
                            class="form-control form-control-lg"
                            style={{height:40}}
                        /> 
                        {loading ? 
                            <CircularProgress sx={{ mt: 1, mb: 1, }} disableShrink /> : 
                            <button
                                className="buttonStyle" 
                                type="submit" 
                                class="browse btn btn-primary px-5"
                                style={{paddingLeft:10}}
                            >Submit</button> 
                        }  
                    </form>
                </div>
        
                <div className="Appss">
                    <Table striped>
                        <thead>
                            <tr>
                            <th>Name</th>
                            <th>Order ID</th>
                            <th>Order Created On</th>
                            <th>Payment Status</th>
                            <th>Order Created On</th>
                            <th>Status</th>
                            <th>Pickup Slot</th>
                            <th>Order Price</th>
                            <th>Pickup Person Name</th>
                            <th>Phone Number</th>
                            </tr>
                        </thead>
                        <tbody>
                        {data.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{item.customer_first_name + item.customer_last_name}</td>
                                            <td>{item.order_id}</td>
                                            <td>₹{item.product_price}</td>
                                            <td>{item.payment_status}</td>
                                            <td>{moment(item.created_at).format("DD-MM-YYYY , hh:mm A")}</td>
                                            <td>{getStatus(item.status)}</td>
                                            <td>{item.pickup_slot}</td>
                                            <td>₹{item.order_price}</td>
                                            <td>{item.contact_person_name}</td>
                                            <td>{item.contact_person_phone_number}</td>
                                        </tr>
                                    )
                        })}
                        </tbody>
                </Table>
                </div>
            </div>
        </div>
    </div>
  
}

export default Dashboard;
