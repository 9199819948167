import React, { useEffect, useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate,useLocation } from "react-router-dom";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {Button,TextField,CircularProgress } from '@mui/material';
import Image from 'react-bootstrap/Image';
import { RecaptchaVerifier, signInWithPhoneNumber } from "@firebase/auth";
import { auth } from "./setup";
import axios from "axios";
const LoginPage = (props) => {
    const [phone, setPhone] = useState("");
    const [user, setUser] = useState("");
    const [loading, setloading] = useState(false);
    const [loadings, setloadings] = useState(false);
    const [phoneError, setPhoneError] = useState("");
    const navigate = useNavigate();
    useEffect(()=>{
        var token = localStorage.getItem('token');
        if(token && token != ''){
            navigate('/dashboard');
        }
    }, []);
    const onButtonClick = () => {
        setPhoneError("")
        if ("" === phone) {
            setPhoneError("Please enter your phone number")
            toast("Please enter phone number")
            return
        }else{
            global.phone = phone;
            sendOtp();
        }
    }
    const sendOtp = async() => {
        setloadings(true);
        try {
            const recaptcha = new RecaptchaVerifier(auth,"recaptcha",{});
            global.confirmation = await signInWithPhoneNumber(auth,phone,recaptcha);
            setTimeout(() => {
                navigate('/otp',{ state: { user:user} });
                console.log("otp send", global.confirmation);
                setloadings(false);
                toast("OTP sent on " + phone)
            }, 1200);
        } catch (error) {
            console.log(error);
            setloadings(false);
        }
    setloading(false);
    }

    const submitOtps = async() => {
        setloading(true);
                try {
                    navigate('/dashboard')
                    // const res = await axios.post("http://139.59.77.157/api/v1/login-user", {
                    //     phone_number: phone.substr(phone.length - 10),
                    //     login_type: 'phone_number',
                    // });
                    // if (res?.data?.token) {
                    //     navigate('/dashboard')
                    //     setloading(false);
                    // }
                } catch (error) {
                    console.log('error', error);
                    setloading(false);
                }
          }

    const onSubmitSignIn = (event) => {
        setloading(true);
        try {
            event.preventDefault()
            fetch("http://139.59.77.157/api/v1/login-user", {
                method: 'POST',
                headers: {
                    // 'Accept': '*application/json'
                    'Accept': '*/*',
                    'Content-Type': 'application/json'}, 
                body: JSON.stringify({
                    phone_number: '7011054769',//phone.substr(phone.length - 10),
                    login_type: 'phone_number',
                }),
            })
            .then(response => response.json())
            .then(res => {
                console.log(res?.token);
                if (res?.token) {
                    console.log("name data",res?.full_name)
                    navigate('/dashboard')
                    setloading(false);
                    console.log("data geted",res.token);
                    global.noddydata.token= res.token;   
                }else{
                    setloading(false);
                    console.log('something wrong');   
                }
            }) 
        } catch (error) {
            console.log(error);   
        } 
        }
const ImgBG = require('../images/completeLogo.png');
    return <div className={"mainContainer"}>
        <div class="mb-2">
        <Image src={ImgBG} fluid />
        </div>
        <div className={"titleContainer"}>
            <div>Login</div>
        </div>
        <br />
       
        <div className={"inputContainers"}>
        <PhoneInput
            country={'in'}
            value={phone}
            onChange={phone => setPhone("+" + phone)}
        />
             <label className="errorLabel">{phoneError}</label>
        </div>
      
        <br />
    {loadings ? 
        <CircularProgress sx={{ mt: 1, mb: 1, }} disableShrink /> : 
        <div className={"inputContainers"}>
        <Button 
            variant="contained" 
            onClick={onButtonClick}
            type="submit"
            fullWidth
            sx={{ mt: 1, mb: 1 }}
            >Send
        </Button>
        </div>
    }
  
    <div style={{marginTop:15}} id="recaptcha"></div>
        <Toaster />
        <Toaster />
    </div>
}

export default LoginPage;
