import React, { useState,useCallback } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import {Button,TextField,CircularProgress  } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import { RecaptchaVerifier, signInWithPhoneNumber } from "@firebase/auth";
import { auth } from "./setup";
import Image from 'react-bootstrap/Image';
import axios from "axios";
const OTPPage = (props) => {
    const location = useLocation();
    var user = location.state.user;
    const [loading, setloading] = useState(false);
    const [otp, setOtp] = useState("")
    const [otpError, setOtpError] = useState("")
    const ImgBG = require('../images/completeLogo.png');
    const navigate = useNavigate();
    const onButtonClick = () => {
        setOtpError("")
        if ("" === otp) {
            
            setOtpError("Please enter your otp")
            return
        }else{
            console.log("otp",otp)
            submitOtps();
        }
    }
    const submitOtps = async() => {
        setloading(true);
            await global.confirmation.confirm(otp).then(async(result) => {
                console.log("otp validate",result);
                try {
                    const res = await axios.post("http://139.59.77.157/api/v1/login-user", {
                        phone_number: global.phone.substr(global.phone.length - 10),
                        login_type: 'phone_number',
                    });
                    if (res?.data?.token) {
                        global.user = res.data;
                        await localStorage.setItem(
                            'authData',
                            JSON.stringify({
                            token: res.data.token,
                            userId: res.data.user_id,
                            }),
                        );
                        await localStorage.setItem(
                            'token',
                            res.data.token
                        );
                        navigate('/dashboard')
                        toast("Log-in Successfully done");
                    }
                } catch (error) {
                    console.log('error', error);
                    toast("Something went wrong !");
                }
          }).catch((error) => {
            console.log("catch error",error)
            toast("Something went wrong, Catched !");
            setloading(false)
          });
    }

    
    return <div className={"mainContainer"}>
        <div class="mb-2">
        <Image src={ImgBG} fluid />
        </div>
        <div className={"titleContainer"}>
            <div>OTP</div>
        </div>
        <br />
        <div className={"inputContainers"}>
        <TextField
        value={otp}
        onChange={otp => setOtp(otp.target.value)}
        variant="outlined"
        size="small"
        label="Enter OTP"
        />
            <label className="errorLabel">{otpError}</label>
        </div>
        <br />
        {loading ? 
        <CircularProgress sx={{ mt: 1, mb: 1, }} disableShrink /> :
        <div className={"inputContainers"}>
        <Button 
            variant="contained" 
            onClick={onButtonClick}
            type="submit"
            fullWidth
            sx={{ mt: 1, mb: 1 }}
            color="success"
            >Login
        </Button>
        </div>
        }
        
        <Toaster />
    </div>
}

export default OTPPage;
