import { initializeApp } from "firebase/app";
import { getAuth,  } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDOxWKGKocRNljYFqm_2DQlCoC4Om6fYN4",
    authDomain: "noddydeliversapp.firebaseapp.com",
    databaseURL: "https://noddydeliversapp-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "noddydeliversapp",
    storageBucket: "noddydeliversapp.appspot.com",
    messagingSenderId: "76279160578",
    appId: "1:76279160578:web:2e8464f528cfe5cc936c1b",
    measurementId: "G-5TZ8PKL99E"
  };

  const app = initializeApp(firebaseConfig);
  export const auth = getAuth(app);